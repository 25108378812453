import {useEffect, useState} from "react"
import {  getPaqueteByClavePaqueteMayorista } from "../services/paqueteService"
export const usePaqueteDetalleMayorista = ({ClavePaquete}) =>{
    const [paquete, setPaquete] = useState({});
    useEffect(() =>{
        Promise.all([getPaqueteByClavePaqueteMayorista({ClavePaquete})]).then(Response => {
            setPaquete(Response[0]);
        }).catch(Error => {
            setPaquete({});
        })
        
        return () =>{
            setPaquete({});
        }
    },[ClavePaquete])
    return [paquete];
}
import axios from "axios"
import { APIURL } from "../config/siteConfig"
import { getCabecera } from "../utils/getCabecera"
import { getError } from "../utils/errors"
const urlCotizacion = "Cotizacion"

export const getCotizacion = async ({ idCotizacion }) => {
  return await axios.get(
    `${APIURL}/${urlCotizacion}/Get/${idCotizacion}`, {
    headers: getCabecera(),
  }
  ).then((Response) => {
    return Response.data;
  }).catch((Error => {
    return {};
  }))
}

export const getPlazosBanco = ({ PrecioPaquete, IdBanco }) => {
  return axios.get(`${APIURL}/${urlCotizacion}/CreditCard/${PrecioPaquete}/${IdBanco}`,
      {
          headers: getCabecera()
      }).then((Response) => {
          return Response.data;
      }).catch((Error) => {
          return [];
      })
}
//* Calculo de pagos para la cotizacion hibrido
//* GET /api/Cotizacion/Hibrid/{PrecioPaquete}/{FecSalida}/{IdBanco}/{PorCIE}/{PorTC}/{NumPagoCIE}
export const getCalculoHibridoPagos = async ({ PrecioPaquete, FecSalida, IdBanco, PorCIE, PorTC, NumPagoCIE }) => {
  return await axios.get(`${APIURL}/${urlCotizacion}/Hibrid/${PrecioPaquete}/${FecSalida}/${IdBanco}/${PorCIE}/${PorTC}/${NumPagoCIE}`,
      {
          headers: getCabecera(),
      }
  ).then((Response) => {
      return Response.data;
  }).catch((Error => {
      // return getError(Error);
      return []
  }))
}

//* Obtener plan de pagos
//* GET /api/Cotizacion/MonthlyPayments/{IdCotizacion}
export const getPlanPagos = async ({ IdCotizacion }) => {
  return await axios.get(`${APIURL}/${urlCotizacion}/MonthlyPayments/${IdCotizacion}`,
      {
          headers: getCabecera(),
      }
  ).then((Response) => {
      return Response.data;
  }).catch((Error => {
      // return getError(Error);
      return []
  }))
}
export const putAceptarCotizacion = async ({ IdCotizacion }) => {
  return await axios.put(`${APIURL}/${urlCotizacion}/Accepted/${IdCotizacion}`, {

  }, {
    headers: getCabecera(),
  }
  ).then((Response) => {
    console.log("🚀 ~ ).then ~ Response:", Response)
    return Response.data;
  }).catch((Error => {
    return getError(Error);
  }))
}
import "./index.css"
import Contain from "../../global/Contain"
import SubTitle from "../../global/SubTitle"
import Header from "./../../global/Header"
import PaqueteCard from "../../components/PauqeteCard"
import DestinosMenu from "../../components/DestinosMenu"
import { useParams, useLocation } from "react-router-dom"
import { usePaquetes } from "../../hooks/usePaquetes"
import { APIURL } from "../../config/siteConfig"
import Footer from "../../global/Footer"
import { usePaquetesMayorista } from "../../hooks/usePaquetesMayorista"

const Paquetes = () => {
    const { name, idDestino } = useParams()
    const location = useLocation()
    const [paquetes] = usePaquetes({ idDestino: (location?.state?.id) ? location?.state?.id : idDestino })
    const [paquetesMayorista] = usePaquetesMayorista({ idDestino: (location?.state?.id) ? location?.state?.id : idDestino })
    return (
        <div>
            <Header />
            <Contain>
                <div className="Paquetes-main-content">
                    <div className="Paquete-main-content-flex">
                        {
                            (location?.state?.src) &&
                            <img src={location?.state?.src} alt="Logo" />
                        }
                        <SubTitle titulo={name} />
                    </div>
                    <div className="Paquete-main-content-flex">
                        <DestinosMenu />
                    </div>
                </div>
                <div className="Paquetes-main">
                    {
                        paquetes?.map((paquete) =>
                            <PaqueteCard
                                key={paquete.IdPaquete}
                                id={paquete.IdPaquete}
                                clavePaquete={paquete.ClavePaquete}
                                folio={paquete.FolioPaquete}
                                src={`${APIURL}/Trip/Package/${paquete.ClavePaquete}/Miniatura`}
                                nombre={paquete.Nombre}
                                dias={paquete.Dias}
                                noches={paquete.Noches}
                                precio={paquete.PrecioPublico}
                                moneda={(paquete.Moneda) ? paquete.Moneda.Clave : ""}
                            />
                        )
                    }
                    {
                        paquetesMayorista?.map((paquete) =>
                            <PaqueteCard
                                key={paquete?.IdPaqueteMayorista}
                                id={paquete?.IdPaqueteMayorista}
                                clavePaquete={paquete?.ClavePaquete}
                                folio={paquete?.ClavePaquete}
                                src={`${APIURL}/Trip/Package/${paquete?.ClavePaquete}/Miniatura`}
                                nombre={paquete?.Nombre}
                                dias={paquete?.Dias}
                                noches={paquete?.Noches}
                                precio={paquete?.PrecioPublico}
                                moneda={(paquete?.Moneda) ? paquete?.Moneda?.Clave : ""}
                                mayorista={true}
                            />
                        )
                    }
                </div>
            </Contain>
            <Footer />
        </div>
    )
}
export default Paquetes;
import axios from "axios";
import { APIURL } from "./../config/siteConfig";
import { getCabecera } from "../utils/getCabecera";

const urlPackage = "Package";

export const getPaqueteDestinosPais = async ({idPaquete}) => {
  const response = await axios.get(
    `${APIURL}/${urlPackage}/Pais/Destination/${idPaquete}`,
    {
      headers: getCabecera(),
    }
  );
  return response.data;
};


export const getPaqueteDestinos = async (IdPaquete) => {
  const response = await axios.get(
    `${APIURL}/${urlPackage}/Destination/${IdPaquete}`,
    {
      headers: getCabecera(),
    }
  );
  return response.data;
};

export const getPaqueteAll = async ({idDestino}) => {
  let paquetes = [];
  const response = await axios.get(`${APIURL}/${urlPackage}/Category/${idDestino}`, {
    headers: getCabecera(),
  });
  paquetes = response.data ?? [];
  return await Promise.all(
    paquetes.map(async (paquete) => {
      return {
        ...paquete,
        Destinos: await getPaqueteDestinos(paquete.IdPaquete),
      };
    })
  );
};
export const getPaqueteOfertaAll = async ({Busqueda}) => {
  let paquetes = [];
  const response = await axios.get(`${APIURL}/${urlPackage}/Offer/${Busqueda}`, {
    headers: getCabecera(),
  });
  paquetes = response.data ?? [];
  return await Promise.all(
    paquetes.map(async (paquete) => {
      return {
        ...paquete,
        Destinos: await getPaqueteDestinos(paquete.IdPaquete),
      };
    })
  );
};
export const getPaqueteOfertLimitAll = async ({Busqueda}) => {
  let paquetes = [];
  const response = await axios.get(`${APIURL}/${urlPackage}/Offer/limit/${Busqueda}`, {
    headers: getCabecera(),
  });
  paquetes = response.data ?? [];
  return await Promise.all(
    paquetes.map(async (paquete) => {
      return {
        ...paquete,
        Destinos: await getPaqueteDestinos(paquete.IdPaquete),
      };
    })
  );
};

export const getPaqueteById = async ({idPaquete}) =>{
  return await axios.get(`${APIURL}/${urlPackage}/${idPaquete}`, {
    headers: getCabecera(),
  }).then((Response) => {
    return Response.data;
  });
};
export const getPaqueteByClavePaquete = async ({ClavePaquete}) =>{
  return await axios.get(`${APIURL}/${urlPackage}/ClavePaquete/${ClavePaquete}`, {
    headers: getCabecera(),
  }).then((Response) => {
    return Response.data;
  });
};

export const getPaqueteGaleria = async ({clavePaquete, idTipoImagen}) =>{
  return await axios.get(
    `${APIURL}/${urlPackage}/Gallery/${clavePaquete}/${idTipoImagen}`,
    {
      headers: getCabecera(),
    }
  ).then((Response) =>{
    return Response.data;
  }).catch((Error) =>{
    return Error.response.data;
  })
}
//* Consulta las mensualidades de una cotizacion 
export const getMontoMensual = async ({ MontoPaquete, FechaViaje }) => {
  return await axios.get(`${APIURL}/${urlPackage}/MonthlyPayments/${MontoPaquete}/${FechaViaje}`, {
    headers: getCabecera()
  }).then((Response) => {
    return Response.data;
  }).catch((Error) => {
    return []
    // return getError(Error);
  })
}
//* consulta los paquetes asociados a una landing page
//* GET /api/Package/Landing/{IdLanding}
export const getPaqueteLangin = async ({IdLanding}) => {
  return await axios.get(`${APIURL}/${urlPackage}/Landing/${IdLanding}`, {
    headers: getCabecera()
  }).then((Response) => {
    return Response.data
  }).catch((Error) => {
    return []
    // return getError(Error);
  })
}
//* Consulta paquetes mayorista 
//* GET /api/Package/V2/Wholesaler/Category/{IdCategoria}
export const getPaqueteAllMayorista = async ({idDestino}) => {
  let paquetes = [];
  const response = await axios.get(`${APIURL}/${urlPackage}/V2/Wholesaler/Category/${idDestino}`, {
    headers: getCabecera(),
  });
  return paquetes = response.data ?? [];
  // return await Promise.all(
  //   paquetes.map(async (paquete) => {
  //     return {
  //       ...paquete,
  //       Destinos: await getPaqueteDestinos(paquete.IdPaquete),
  //     };
  //   })
  // );
};
//* Consulta paquete mayorista v2
//* GET /api/Package/V2/Wholesaler/Key/{ClavePaquete}
export const getPaqueteByClavePaqueteMayorista = async ({ClavePaquete}) =>{
  return await axios.get(`${APIURL}/${urlPackage}/V2/Wholesaler/Key/${ClavePaquete}`, {
    headers: getCabecera(),
  }).then((Response) => {
    return Response.data;
  });
};
//* Consulta la galeria paquete mayorista 
export const getPaqueteMayoristaGaleria = async ({clavePaquete, idTipoImagen}) =>{
  return await axios.get(
    `${APIURL}/${urlPackage}/V2/Wholesaler/Gallery/${clavePaquete}/${idTipoImagen}`,
    {
      headers: getCabecera(),
    }
  ).then((Response) =>{
    return Response.data;
  }).catch((Error) =>{
    return Error.response.data;
  })
}
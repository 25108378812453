import {useEffect, useState} from "react"
import { getPlanPagos } from "../services/cotizacionService";
import { getMensualidadesBanco } from "../services/banckService";
export const usePlanPagos = ({idCotizacion}) =>{
    const [planPagos, setPlanPagos] = useState([]);
    useEffect(() =>{
        Promise.all([getPlanPagos({IdCotizacion: idCotizacion})]).then(Response => {
            setPlanPagos(Response[0]);
        }).catch(Error => {
            setPlanPagos([]);
        })
        
        return () =>{
            setPlanPagos([]);
        }
    },[idCotizacion])
    return [planPagos];
}
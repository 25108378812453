import styles from "./index.module.css"
import { useState, useRef } from "react";
const VideoMovil = ({ ruta }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleVideoClick = () => {
        // Alterna entre Play y Pausa al hacer clic en el video
        togglePlayPause();
    };

    const handleVideoEnded = () => {
        // Muestra el botón de Play cuando el video termina
        setIsPlaying(false);
    };
    return (
        <div className={styles.phoneFrame}>
            <div className={styles.notch}></div>
            <div className={styles.phoneScreen}>
                <video
                    ref={videoRef}
                    src={ruta}
                    onClick={handleVideoClick}
                    onEnded={handleVideoEnded}
                />
                {!isPlaying && (
                    <button className={styles.playButton} onClick={togglePlayPause}>
                        ►
                    </button>
                )}
            </div>
        </div>
    );
};

export default VideoMovil

import {useEffect, useState} from "react"
import { getPaqueteAllMayorista } from "../services/paqueteService"
export const usePaquetesMayorista = ({idDestino}) =>{
    const [paquetes, setPaquetes] = useState([]);
    useEffect(() =>{
        Promise.all([getPaqueteAllMayorista({idDestino})]).then(Response => {
            setPaquetes(Response[0]);
        }).catch(Error => {
            setPaquetes([]);
        })
        
        return () =>{
            setPaquetes([]);
        }
    },[idDestino])
    return [paquetes];
}
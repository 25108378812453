import Contain from "../../global/Contain"
import classes from "./index.module.css"
import { useParams } from "react-router"
import LogoDoodo from "./../../assets/Logo_Dooddo_Ahorro2.png"
import { useCotizacion } from "../../hooks/useCotizacion"
import { formatDineroCentavos } from "./../../utils/getDinero"
import { formatAddMonth, FormatoFecha } from "./../../utils/getFecha"
import { usePaqueteClavePaquete } from "../../hooks/usePaqueteClave"
import { useDetallePaquete } from "../../hooks/usePaqueteDetalle"
import { usePlanPagos } from "../../hooks/usePlanPagos"

const labelTipoPago = ({ formatoPago }) => {
    if (formatoPago.length > 1) {
        return "Hibrido"
    }
    const label = formatoPago.map(pago => {
        if (pago?.CatTipoPago?.IdCatTipoPago === 1) {
            return "Promoción"
        }
        if (pago?.CatTipoPago?.IdCatTipoPago === 2) {
            return "Meses diferidos"
        }
    })
    return label
}

function formatSentence(text) {
    if (!text) return '';
    const trimmedText = text.trim();
    return trimmedText.charAt(0).toUpperCase() + trimmedText.slice(1).toLowerCase();
}

export function MiCotizacion() {
    const { idCotizacion, token } = useParams()
    const [cotizacion, load] = useCotizacion({ idCotizacion })
    const [paquete] = usePaqueteClavePaquete({ ClavePaquete: cotizacion?.ClavePaquete })
    const [detalle] = useDetallePaquete({ idPaquete: paquete?.IdPaquete })
    const formatoPago = cotizacion?.lFormatoPago
    const [planPagos] = usePlanPagos({ idCotizacion: idCotizacion })
    const ObtenerSuplemento = ({ busqueda }) => {
        const plaza = cotizacion?.lContizacionSuplemento.find(plaza => plaza.Descripcion === busqueda)
        if (plaza) {
            return plaza.Precio * plaza.Cantidad
        }
        return 0
    }
    if (!load && cotizacion?.IdCotizacion <= 0)
        return <div>
            <Contain>
                <div className={classes.content}>
                    <h1>No se encontro cotización</h1>
                </div>
            </Contain>
        </div>
    if (!load && cotizacion?.IdCotizacion > 0)
        return (
            <div>
                <Contain>
                    <div className={classes.main}>
                        <div className={classes.content}>
                            <img className={classes.logo} src={LogoDoodo} alt="Dooddo" />
                        </div>
                        <div className={classes.content}>
                            <h1>Cotización</h1>
                        </div>
                        <div className={classes.grid2}>
                            <div className={classes.content}>
                                <div className={classes.textLeft}>
                                    <p>{`${cotizacion.Cliente?.Nombre}`}</p>
                                    <p>{`${cotizacion.Cliente?.EMail}`}</p>
                                    <p>{`${cotizacion.Cliente?.Telefono}`}</p>
                                </div>
                            </div>
                            <div className={classes.content}>
                                <div className={classes.textLeft}>
                                    <h5 className={classes.bold}>{`Dooddo viajes`}</h5>
                                    <p>{`Rio lerma 94 piso 3`}</p>
                                    <p>{`AVI160825MVA`}</p>
                                    <p>{`Cdmx, Ciudad de México 06500`}</p>
                                    <p>{`Preparado por: ${cotizacion.Agente?.Nombre} ${cotizacion.Agente?.Apellido_Paterno}`}</p>
                                    <p>{`${cotizacion.Agente?.eMail}`}</p>
                                    <p>{`${cotizacion.Agente?.Telefono_Contacto} Ext. ${cotizacion?.Agente?.ExtensionContacto}`}</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.creacion}>
                            <div className={classes.content}>
                                <div className={classes.textLeft}>
                                    <p>Fecha de creacion:</p>
                                </div>
                            </div>
                            <div className={classes.content}>
                                <div className={classes.textLeft}>
                                    <p>{FormatoFecha({ date: cotizacion.FechaRegistro, formato: "dd/MM/yyyy" })}</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.divider}>
                            <h2 className={classes.title}>Cotización</h2>
                        </div>
                        <div className={classes.content}>
                            <table className={classes.tabla}>
                                <thead>
                                    <tr>
                                        <th style={{ width: "40%" }}>PAQUETE {`${cotizacion.NombrePaquete}`}</th>
                                        <th className={classes.alter}>CANTIDAD</th>
                                        <th className={`${classes.alter} ${classes.noBorder}`}>PRECIO TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div
                                                style={{ textTransform: "capitalize" }}
                                                className={classes.textLeft}
                                                dangerouslySetInnerHTML={{
                                                    __html: formatSentence(cotizacion.EnvioPaqueteProveedor?.Descripcion || '')
                                                }}
                                            />
                                            <p className={classes.textVerde}>NO INCLUYE</p>
                                            <div
                                                style={{ textTransform: "capitalize" }}
                                                className={classes.textLeft}
                                                dangerouslySetInnerHTML={{
                                                    __html: formatSentence(detalle?.NoIncluye || '')

                                                }}
                                            />
                                        </td>
                                        <td>
                                            {(cotizacion.Adultos > 0) ? `${cotizacion.Adultos} Adultos` : ''}
                                            <br />
                                            {(cotizacion.Menores > 0) ? `${cotizacion.Menores} Menores` : ''}
                                            <br />
                                            {(cotizacion.Infantes > 0) ? `${cotizacion.Infantes} Infantes` : ''}
                                        </td>
                                        <td className={classes.noBorder}>
                                            {(cotizacion.Adultos > 0) ? `${formatDineroCentavos(cotizacion.EnvioPaqueteProveedor?.Precio * cotizacion.Adultos)} ${cotizacion.Moneda?.Clave}` : ''}
                                            <br />
                                            {(cotizacion.Menores > 0) ? `${formatDineroCentavos(ObtenerSuplemento({ busqueda: "Menor e infante" }))} ${cotizacion.Moneda?.Clave}` : ''}
                                            <br />
                                            {(cotizacion.Infantes > 0) ? `${formatDineroCentavos(ObtenerSuplemento({ busqueda: "Infante" }))} ${cotizacion.Moneda?.Clave}` : ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 className={classes.title}>SALIDA</h5>
                                            <p>{FormatoFecha({ date: cotizacion?.FechaSalida, formato: "dd/MM/yyyy" }).toUpperCase()}</p>
                                        </td>
                                        <td>
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'SALIDA')
                                                        return item.Cantidad
                                                })
                                            }
                                        </td>
                                        <td className={classes.noBorder}>
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'SALIDA' && item.Precio > -1)
                                                        return `${formatDineroCentavos(item?.Precio * item.Cantidad)} ${cotizacion.Moneda?.Clave}`
                                                    if (item.TipoSuplemento === 'SALIDA' && item.Precio < -1)
                                                        return <br />
                                                })
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5 className={classes.title}>TIPO DE PLAZA</h5>
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'PLAZA' && !item.Descripcion.includes("infante") && !item.Descripcion.includes("Infante"))
                                                        return <p key={index}>{`Habitación / ${item.Descripcion}`}</p>
                                                })
                                            }
                                        </td>
                                        <td>
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'PLAZA' && !item.Descripcion.includes("infante") && !item.Descripcion.includes("Infante"))
                                                        return <p key={index}>{`${item.Cantidad}`}</p>
                                                })
                                            }
                                        </td>
                                        <td className={classes.noBorder}>
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'PLAZA' && !item.Descripcion.includes("infante") && !item.Descripcion.includes("Infante") && item.Precio > 0)
                                                        return <p key={index}>{`${formatDineroCentavos(item.Precio)} ${cotizacion.Moneda?.Clave}`}</p>
                                                    if (item.TipoSuplemento === 'PLAZA' && !item.Descripcion.includes("infante") && !item.Descripcion.includes("Infante") && item.Precio === 0)
                                                        return <br />
                                                })
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {
                                                cotizacion?.lContizacionSuplemento.some(item => item.TipoSuplemento === 'EXTRA')
                                                    ? <h5 className={classes.title}>EXTRAS</h5>
                                                    : ''
                                            }
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'EXTRA')
                                                        return <p key={index}>{`EXTRA / ${item.Descripcion}`}</p>
                                                })
                                            }

                                        </td>
                                        <td>
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'EXTRA')
                                                        return <p key={index}>{`${item.Cantidad}`}</p>
                                                })
                                            }
                                        </td>
                                        <td className={classes.noBorder}>
                                            {
                                                cotizacion?.lContizacionSuplemento.map((item, index) => {
                                                    if (item.TipoSuplemento === 'EXTRA' && item.Precio > 0)
                                                        return <p key={index}>{`${formatDineroCentavos(item.Precio * item.Cantidad)} ${cotizacion.Moneda?.Clave}`}</p>
                                                    if (item.TipoSuplemento === 'EXTRA' && item.Precio <= 0)
                                                        return <br />

                                                })
                                            }

                                        </td>
                                    </tr>
                                    {
                                        (cotizacion?.MontoInteres > 0) &&
                                        <tr>
                                            <td>
                                                <h5 className={classes.title}> MESES DIFERIDOS</h5>
                                                <p>{`Meses Diferidos`}</p>
                                            </td>
                                            <td>
                                            </td>
                                            <td className={classes.noBorder}>
                                                {`${formatDineroCentavos(cotizacion?.MontoInteres)} ${cotizacion.Moneda?.Clave}`}
                                            </td>
                                        </tr>
                                    }

                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <h5 className={classes.title}>TOTAL</h5>
                                        </td>
                                        <td className={classes.noBorder} style={{ fontWeight: "bold" }}>
                                            {`${formatDineroCentavos(cotizacion?.MontoPaquete)} ${cotizacion.Moneda?.Clave}`}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* <div className={classes.content}>
                            <p className={classes.textCaption}>
                                Nota: Queremos informarle que, aunque se menciona que no se incluyen impuestos aéreos, esta información es incorrecta. Los impuestos aéreos sí están incluidos en
                                el precio final. (Lamentamos cualquier confusión. Nos encontramos trabajando en resolver este problema lo mas pronto posible
                            </p>
                        </div> */}
                        <div className={classes.divider}>
                            <h2 className={classes.title}>Vigencia</h2>
                        </div>
                        <div className={classes.viegencia}>
                            <div className={classes.content}>
                                <h5>Vigencia de cotización</h5>
                            </div>
                            <div className={classes.content}>
                                <h5>{FormatoFecha({ date: cotizacion?.FechaExpiracion, formato: "dd/MM/yyyy" })}</h5>
                            </div>
                        </div>
                        <div className={classes.content}>
                            <div>
                                <h6 className={classes.vigenciaCaptionTitle}>Importante:</h6>
                                <p className={classes.vigenciaCaption}>
                                    Aceleradora de Viajes (Dooddo) nos dedicamos a hacer tours y viajes en grupo a los mejores precios. Por ello, la capacidad de
                                    nuestro servicio terrestre y aéreo se limita a los lugares asignados por la aerolínea (35/45 lugares).
                                    Debido a la alta demanda, la cotización sólo es válida por tiempo limitado.
                                    Al momento de hacer tu primer pago aseguras tu lugar dentro de la fecha de salida seleccionada
                                </p>
                            </div>
                        </div>
                        <div className={classes.divider}>
                            <h2 className={classes.title}> Plan de pagos / {labelTipoPago({ formatoPago })}</h2>
                        </div>
                        {
                            (formatoPago.length > 0) &&
                            <div>
                                <div className={classes.content}>
                                    <table className={`${classes.tabla} ${classes.noBorder}`}>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "20%" }}># DE PAGO</th>
                                                <th>FECHA LÍMITE DE PAGO</th>
                                                <th>CANTIADAD</th>
                                                <th className={`${classes.noBorder}`}>MÉTODO DE PAGO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                formatoPago.map(pago => {
                                                    if (pago?.CatTipoPago?.IdCatTipoPago === 1) {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td className={classes.noBorder} rowspan={planPagos.length}>
                                                                        <img className={classes.logoBank} src="https://assets.dooddo.com/bank/1.png" alt="plazsos" />
                                                                        <img className={classes.logoBank} src="https://assets.dooddo.com/bank/0.1.png" alt="plazsos" />
                                                                        <br />
                                                                        <p>Debito / Practicaja</p>
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    planPagos.map((mensualidad, index) => {
                                                                        if (mensualidad?.CatTipoPago?.IdCatTipoPago === 1) {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{FormatoFecha({ date: mensualidad?.FechaLimitePago, formato: "dd/MM/yyyy" }).toUpperCase()}</td>
                                                                                    <td>{`${formatDineroCentavos(mensualidad?.Monto)} ${cotizacion.Moneda?.Clave}`}</td>
                                                                                </tr>
                                                                            )
                                                                        }

                                                                    })
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    if (pago?.CatTipoPago?.IdCatTipoPago === 2) {
                                                        const numeroPagos = pago?.NumeroPagos
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td rowspan={3} className={classes.noBorder}>
                                                                        {/* <img style={{ width: "100%", height: "auto", maxWidth: "100px" }} src={`https://assets.dooddo.com/bank/${pago?.BancoFormatoPago?.Banco?.IdBanco}.png`} alt="plazsos" /> */}
                                                                        <img className={classes.logoBank} src="https://assets.dooddo.com/bank/86.png" alt="plazsos" />
                                                                        <img className={classes.logoBank} src="https://assets.dooddo.com/bank/87.png" alt="plazsos" />
                                                                        <img className={classes.logoBank} src="https://assets.dooddo.com/bank/logo_ae.png" alt="plazsos" />
                                                                        <p>Tarjeta de crédito</p>
                                                                    </td>
                                                                </tr>

                                                                {
                                                                    planPagos.map((mensualidad, index) => {
                                                                        if (mensualidad?.CatTipoPago?.IdCatTipoPago === 2) {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td className={classes.trCredito}>
                                                                                        {index + 1}
                                                                                        <p className={classes.textVerde}>{`Diferido ${numeroPagos} meses`}</p>
                                                                                    </td>
                                                                                    <td className={classes.trCredito}>
                                                                                        {FormatoFecha({ date: mensualidad?.FechaLimitePago, formato: "dd/MM/yyyy" }).toUpperCase()}
                                                                                        <p className={classes.textVerde}>{`${FormatoFecha({ date: mensualidad?.FechaLimitePago, formato: "MM/yyyy" }).toUpperCase()} al ${formatAddMonth({ date: mensualidad?.FechaLimitePago, months: numeroPagos, formato: "MM/yyyy" }).toUpperCase()}`}</p>
                                                                                    </td>
                                                                                    <td className={classes.trCredito}>
                                                                                        {`${formatDineroCentavos(mensualidad?.Monto)} ${cotizacion.Moneda?.Clave}`}
                                                                                        <p className={classes.textVerde}>{`Mensual ${formatDineroCentavos(mensualidad?.Monto / numeroPagos)} ${cotizacion.Moneda?.Clave}`}</p>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }

                                                                    })
                                                                }
                                                            </>
                                                        )
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        <div className={classes.content}>
                            <p className={classes.textFinal}>
                                This communication is private and confidential. It is intended solely for the use of the individual or entity to whom it is addressed and may contain privileged and
                                confidential information. If you are not the intended recipient, please be notified that any dissemination, distribution, or copying of this communication is strictly prohibited.
                                Kindly notify the sender immediately and delete the original message. Unauthorized disclosure or use of this information is prohibited, and any sharing of this
                                communication requires written authorization from the company. / Esta comunicación es privada y confidencial. Está destinada únicamente para el uso de la persona o
                                entidad a la que está dirigida y puede contener información privilegiada y confidencial. Si usted no es el destinatario previsto, por favor tenga en cuenta que cualquier
                                divulgación, distribución o copia de esta comunicación está estrictamente prohibida. Por favor notifique al remitente inmediatamente y elimine el mensaje original. La
                                divulgación o uso no autorizado de esta información está prohibida, y cualquier compartición de esta comunicación requiere autorización escrita de la empresa.
                            </p>
                        </div>
                    </div>
                </Contain>
            </div>
        )
    return <div>
        <Contain>
            <div className={classes.content}>
                <h1>Cargando...</h1>
            </div>
        </Contain>
    </div>
}

import "./index.css"
import Contain from "../../global/Contain"
import SubTitle from "../../global/SubTitle"
import Header from "../../global/Header"
import DestinosMenu from "../../components/DestinosMenu"
import TabDetalle from "../../components/TabDetalle"
import CardDetalle from "../../components/CardDetalle"
import GaleriaPortada from "./../../assets/detalle/galeria/Galeria1.png"
import Galeria1 from "./../../assets/detalle/galeria/Galeria2.png"
import Galeria2 from "./../../assets/detalle/galeria/Galeria3.png"
import BotonGaleria from "../../components/GaleriaPaquete"
import { useLocation, useParams } from "react-router"
import FormularioDetalle from "../../components/FormularioModal"
import { APIURL } from "../../config/siteConfig"
import { catTipoImagen } from "../../config/catTipoImagen"
import Footer from "../../global/Footer"
import { usePaqueteDetalleMayorista } from "../../hooks/usePaqueteDetalleMayorista"
import { useGaleriaMayorista } from "../../hooks/useGaleriaMayorista"
const PaqueteDetalleMayorista = () => {
    const { clavePaquete } = useParams()
    const location = useLocation();
    const [paquete] = usePaqueteDetalleMayorista({  ClavePaquete: (location?.state?.clavePaquete) ? location?.state?.clavePaquete : clavePaquete })
    const [paqueteGaleria] = useGaleriaMayorista({ clavePaquete, idTipoImagen: catTipoImagen.GALERIA })

    const arrayGaleryParse = () => {
        return paqueteGaleria.map((item) => {
            return {
                src: `${APIURL}/Package/V2/Wholesaler/Gallery/Image/${item?.IdPaqueteMayoristaImagen}`,
                alt: `${item?.NombreImagen}`,
            }
        })
    }
    return (
        <div>
            <Header />
            <div className="PaquetesDetellaContentMain">
                <FormularioDetalle />
                <Contain>
                    <div className="Paquetes-Detalle-main-content">
                        <div className="Paquete-Detalle-main-content-flex">
                            <SubTitle titulo={paquete.Nombre} />
                        </div>
                        <div className="Paquete-Detalle-main-content-flex">
                            <DestinosMenu />
                        </div>
                    </div>
                    <div className="Paquetes-Detalle-main">
                        <div className="PaquetesDetalle-Galeria">
                            <div className="portada">
                                <img src={`${APIURL}/Trip/Package/${clavePaquete}/Portada`} alt="portada" />
                            </div>
                            {
                                (paqueteGaleria.length <= 0) &&
                                <div className="galeria">
                                    <div className="galeria-portada default">
                                        <img src={GaleriaPortada} alt="Galeria portada" />
                                    </div>
                                    <div className="galeria-uno default">
                                        <img src={Galeria1} alt="Galeria portada" />
                                    </div>
                                    <div className="galeria-dos default">
                                        <img src={Galeria2} alt="Galeria portada" />
                                    </div>
                                    <div className="galery-Boton">
                                        <BotonGaleria>
                                            ver más
                                        </BotonGaleria>
                                    </div>
                                </div>
                            }
                            {
                                (paqueteGaleria.length > 0) &&
                                <div className="galeria">
                                    <div className="galeria-portada">
                                        <img src={`${APIURL}/Package/V2/Wholesaler/Gallery/Image/${paqueteGaleria[0]?.IdPaqueteMayoristaImagen}`} alt="Galeria portada" />
                                    </div>
                                    <div className="galeria-uno">
                                        <img src={`${APIURL}/Package/V2/Wholesaler/Gallery/Image/${paqueteGaleria[1]?.IdPaqueteMayoristaImagen}`} alt="Galeria portada" />
                                    </div>
                                    <div className="galeria-dos">
                                        <img src={`${APIURL}/Package/V2/Wholesaler/Gallery/Image/${paqueteGaleria[2]?.IdPaqueteMayoristaImagen}`} alt="Galeria portada" />
                                    </div>
                                    <div className="galery-Boton">
                                        <BotonGaleria galeria={arrayGaleryParse()}>
                                            ver más
                                        </BotonGaleria>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="PaquetesDetalle-Flotante">
                            <CardDetalle
                                id={paquete.IdPaqueteMayorista}
                                dias={paquete.Dias}
                                noches={paquete.Noches}
                                precio={paquete.MontoMensualidad}
                                fechaSalida={paquete.UltimaFechaSalida}
                                moneda={(paquete.Moneda) ? paquete.Moneda.Clave : ""}
                                precioDescuento={paquete?.PrecioPublico}
                                precioNormal={paquete?.PrecioTC}
                            />
                        </div>
                        <div className="PaquetesDetalle-Content">
                            <TabDetalle
                                id={paquete.IdPaqueteMayorista}
                                precioPublico={paquete.PrecioPublico}
                                moneda={(paquete.Moneda) ? paquete.Moneda.Clave : ""}
                            />
                        </div>
                    </div>
                </Contain>
            </div>
            <Footer />
        </div>
    )
}
export default PaqueteDetalleMayorista;